import React from "react";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { graphql, useStaticQuery } from "gatsby";
import Body from "../components/body";

function NotFoundPage() {
  const data = useStaticQuery(graphql`
    query {
      backgroundMobile: file(relativePath: { eq: "hero-image/404.png" }) {
        childImageSharp {
          fluid(maxWidth: 850, maxHeight: 1600, fit: COVER, cropFocus: WEST) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        relativePath
      }
      backgroundDesktop: file(relativePath: { eq: "hero-image/404.png" }) {
        ...FluidImageFragment
      }
    }
  `);
  const backgroundImages = [
    data.backgroundMobile.childImageSharp.fluid,
    {
      ...data.backgroundDesktop.childImageSharp.fluid,
      media: "(min-width: 425px)"
    }
  ];
  return (
    <Body>
      <SEO title="404: Not found" />
      <BackgroundImage
        Tag="section"
        className={"relative h-screen"}
        fluid={backgroundImages}
        style={{
          backgroundSize: "cover",
          backgroundPositionX: "0%",
          backgroundPositionY: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#010a2b"
        }}
      >
        <div className="container max-w-6xl justify-center flex flex-col items-center h-full mx-auto text-center">
          <div className="w-full max-w-xs mb-6">
            <img
              alt="404 Feeling Lost?"
              className="px-4"
              src="/assets/404-message.svg"
            />
          </div>
          <a
            className="cta-404 text-xl font-bold mt-3 border-light text-light border-2 rounded-full px-3 py-1 font-medium"
            href="/#"
          >
            Find your way back
          </a>
        </div>
      </BackgroundImage>
    </Body>
  );
}

export default NotFoundPage;
